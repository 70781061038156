<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>Colors</b-card-title>
      <b-card-sub-title>Colors of the app</b-card-sub-title>
      <b-row class="mt-2 px-2">
        <b-col>
          <b-img
            v-bind="mainProps"
            :blank-color="appData.primaryColor"
            rounded
            alt="app primary color"
            class="d-inline-block mr-1 mb-1 border"
          />
          <div>Primay Color</div>
        </b-col>
        <b-col>
          <b-img
            v-bind="mainProps"
            :blank-color="appData.primaryDarkColor"
            rounded
            alt="app primary dark color"
            class="d-inline-block mr-1 mb-1 border"
          />
          <div>Primary Dark Color</div>
        </b-col>
        <b-col>
          <b-img
            v-bind="mainProps"
            :blank-color="appData.accentColor"
            rounded
            alt="app secondary color"
            class="d-inline-block mr-1 mb-1 border"
          />
          <div>Accent Color</div>
        </b-col>
        <b-col>
          <b-img
            v-bind="mainProps"
            :blank-color="appData.websiteColor"
            rounded
            alt="app website background color"
            class="d-inline-block mr-1 mb-1 border"
          />
          <div>Website Color</div>
        </b-col>
        <b-col>
          <b-img
            v-bind="mainProps"
            :blank-color="appData.splashColor"
            rounded
            alt="app splash screen background color"
            class="d-inline-block mr-1 mb-1 border"
          />
          <div>Splash Screen Color</div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BImg,
  BCard, BCardBody, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    mainProps: {
      blank: true,
      width: 75,
      height: 75,
      class: 'm1',
    },
  }),
  setup() {
    const permissionsData = [
      {
        module: 'Admin',
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'Staff',
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: 'Author',
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: 'Contributor',
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: 'User',
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ]

    return {
      permissionsData,
    }
  },
}
</script>

<style>

</style>
