<template>
  <!-- Field: Appname -->
  <b-col
    class="card-container"
    md="6"
    lg="3"
  >
    <b-card
      id="elevated-card"
      class="text-center"
      :border-variant="props.isconfigured ? 'success':'danger'"
    >
      <div>
        <feather-icon
          v-if="!props.isconfigured"
          icon="InfoIcon"
          size="32"
          class="mr-0 mr-sm-50 warn-icon"
        />

        <feather-icon
          v-if="props.isconfigured"
          icon="CheckCircleIcon"
          size="32"
          class="mr-0 mr-sm-50 success-icon"
        />
        <b-card-title class="mt-2 heading">
          {{ props.heading }}
        </b-card-title>

        <b-card-text class="description">
          {{ props.description }}
        </b-card-text>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="success"
          :to="props.navpath"
        >
          Configue
        </b-button>
      </div>
    </b-card>
  </b-col>
</template>

<script>
import {
  BCol,
  // BTable,
  BCardTitle,
  BButton,
  BCard,
  BCardText,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BCardText,
    BCardTitle,
    // BFormSelect
  },
  props: {
    heading: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    navpath: {
      type: Object,
      required: true,
    },
    isconfigured: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    return {
      props,
      avatarText,

    }
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-select.scss";
.warn-icon{
    color: #ff9f43;
}
.success-icon{
    color:rgb(11, 221, 11);
}

.card-container {
  display: flex; /*Make sure to call this on your container*/
  align-items: stretch; /*stretches all cards same height*/
  justify-content: space-around; /*some space between cards*/
}

#elevated-card {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  /* Add shadows to create the "card" effect */
    // border-radius: 5%;
}
// .heading{
//   min-height: 3rem;
// }
// .description{
//   min-height: 2rem;
// }

#elevated-card.border-success {
  /* Add shadows to create the "card" effect */
    border: 2px solid #28c76f !important
}

#elevated-card.border-danger {
  /* Add shadows to create the "card" effect */
    // border: 2px solid #e72720 !important
       border: 2px solid #ff9f43 !important
}

/* On mouse-over, add a deeper shadow */
#elevated-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2) !important ;
}
@media (max-width: 560px) {
  .color-horizontal-align {
    padding-bottom: 1rem;
  }
}

</style>
