<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="appData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching App data
      </h4>
      <div class="alert-body">
        No App found with this App id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'home'}"
        >
          Home
        </b-link>
        to go back to homepage.
      </div>
    </b-alert>

    <template v-if="appData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
          <user-view-user-info-card :app-data="appData" />
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="3"
          lg="4"
        >
          <app-view-build-card :is-loading="isLoading" :site-id="appData.siteId" :status="appData.status" :download-url="appData.build_download_url"/>
        </b-col>
      </b-row>
      <section id="card-text-alignment">
        <b-row>
          <!-- left align -->
          <app-view-configure-card
            heading="Configure Splash Screen"
            description="Add or configure the splashscreen for your app"
            :navpath="{ name: 'edit-app', params: { id: appData.siteId } ,query: { mode: 'splash' } }"
            :isconfigured="appData.splashScreen ? true:false"
          />
          <app-view-configure-card
            heading="Configure Intro Screens"
            description="Add or configure the Info screens for your app"
            :navpath="{ name: 'edit-app', params: { id: appData.siteId } ,query: { mode: 'info' } }"
            :isconfigured="appData.showIntro ? true:false"
          />
          <app-view-configure-card
            heading="Configure Adds"
            description="   Add or Configure Addvertisements for your app"
            :navpath="{ name: 'edit-app', params: { id: appData.siteId } ,query: { mode: 'splash' } }"
            :isconfigured="appData.enableAdds ? true:false"
          />
          <app-view-configure-card
            heading="Configure Notifications"
            description="Add or configure the Notifications for your app"
            :navpath="{ name: 'edit-app', params: { id: appData.siteId } ,query: { mode: 'notifications' } }"
            :isconfigured="appData.enableNotification ? true:false"
          />
        </b-row>
      </section>
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-timeline-card :app-logs="appLogs" />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-user-permissions-card :app-data="appData" />
        </b-col>
      </b-row>
    </template>
    <b-overlay
      :show="isLoading"
      no-wrap
    />
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { watch, computed } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink, BOverlay,
} from 'bootstrap-vue'

import UserViewUserInfoCard from './AppViewUserInfoCard.vue'
import AppViewBuildCard from './AppViewBuildCard.vue'
import UserViewUserTimelineCard from './AppViewUserTimelineCard.vue'
import UserViewUserPermissionsCard from './AppViewUserPermissionsCard.vue'
import AppViewConfigureCard from './AppViewConfigureCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BOverlay,
    // Local Components
    UserViewUserInfoCard,
    AppViewBuildCard,
    UserViewUserTimelineCard,
    UserViewUserPermissionsCard,
    AppViewConfigureCard,
  },
  data() {
    return {
      isLoading: false,
    }
  },
  setup() {
    // const USER_APP_STORE_MODULE_NAME = 'app-user'

    // // Register module
    // if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    // })

    store.dispatch('appSite/fetchSelectedApp', { id: router.currentRoute.params.id })
      .then(() => {
        // appData.value = store.getters['appSite/selectedApp']
        watch(store.getters['appSite/selectedApp'], () => {
        })
      })
      .catch(error => {
        console.log(error)
      })

    store.dispatch('appSite/fetchSelectedAppLogs', { id: router.currentRoute.params.id })
      .then(() => {
        // appData.value = store.getters['appSite/selectedApp']
      })
      .catch(error => {
        console.log(error)
      })

    return {
      appData: computed(() => store.getters['appSite/selectedApp']),
      appLogs: computed(() => store.getters['appSite/selectedAppLogs']),
    }
  },
  computed: {

    loadingStatus() {
      return this.$store.getters['loading/loadingStatus']
    },
  },
  watch: {
    loadingStatus(payload) {
      this.isLoading = payload.isLoading
    },
  },
  methods: {
    onConfigureSplashScreenClick() {
      this.$route.push()
    },
  },
  metaInfo: {
    // Overwrite the SEO title.
    title: 'View App',
  },
}
</script>

<style>
.dark-layout .b-overlay .bg-light {
    background-color: #161d31 !important;
}
</style>
