<template>
  <ul
    v-bind="$attrs"
    class="app-timeline"
    v-on="$listeners"
  >
    <slot />
  </ul>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.app-timeline {
  max-height: 300px;
  overflow: auto;
  // overflow-x: auto;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 8px;
  position: relative;
  margin-left: 1rem;
/* Track */
}
/* width */

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: red;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000;
}
</style>
