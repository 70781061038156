<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <b-avatar
            :src="appData.appLogoURL"
            :text="avatarText(appData.siteName)"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <b-row>
                <b-col>
                  <h4 class="mb-0">
                    <b>
                      {{ appData.siteName }}
                    </b>
                  </h4>
                </b-col>
              </b-row>
              <span class="card-text">{{ appData.siteUrl }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                :to="{ name: 'edit-app', params: { id: appData.siteId } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                :disabled="isLoading || appData.status == 'Pending'"
                @click="deleteApp"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BoxIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">App ID</span>
            </th>
            <td class="pb-50">
              {{ appData.siteId }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Created at</span>
            </th>
            <td v-if="appData.createdAt">
              {{ new Date(appData.createdAt.seconds * 1000) | formatDate }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BAvatar,
  BRow,
  BCol,
  VBTooltip,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    appData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      selectedBuildType: 'basic',
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    loadingStatus() {
      return this.$store.getters['loading/loadingStatus']
    },
    buildContinueEnable() {
      if (this.selectedBuildType === 'basic' && this.user.remainingBasicBuilds > 0) {
        return true
      }
      if (this.selectedBuildType === 'premium' && this.user.remainingPremiumBuilds > 0) {
        return true
      }
      if (this.selectedBuildType === 'standard' && this.user.remainingStandardBuilds > 0) {
        return true
      }
      return false
    },
  },
  watch: {
    loadingStatus(payload) {
      this.isLoading = payload.isLoading
    },
  },
  methods: {
    buildClick() {
      this.$store.dispatch('appSite/startBuild', { buildType: this.selectedBuildType })
    },
    deleteApp() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to delete this app and Alll its Data? This cannot be reversed', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$store.dispatch('appSite/deleteApp', this.appData.siteId)
          }
        })
    },
  },
}
</script>

<style>
</style>
