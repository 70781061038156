<template>
  <b-card title="Build Timeline">
    <app-timeline>
      <app-timeline-item
        v-for="(item, index) in appLogs"
        :key="index +1"
        :title="item.title"
        :buildid="item.buildId"
        :subtitle="item.message"
        :time="moment(new Date(item.time.toDate())).fromNow()"
        :variant="item.variant"
      />
      <app-timeline-item
        key="0"
        title="App Created"
        buildid="Successfully created the app"
        subtitle=""
        time=""
        variant="success"
      />
    </app-timeline>
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    BCard,
    AppTimeline,
    AppTimelineItem,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    appLogs: {
      type: Array,
    },
  },
}
</script>

<style>

</style>
