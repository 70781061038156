<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Current Build
      </h5>
      <b-badge :variant="statuses[1][status]">
        {{ statuses[0][status] }}
      </b-badge>
      <small class="text-muted w-100">{{ new Date() | formatDateWithoutTime }}</small>
    </b-card-header>

    <b-card-body>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        :disabled="isLoading || status === 'Pending'"
        @click="showModal"
      >
        Build App
      </b-button>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-primary"
        block
        :disabled="isLoading || status !=='Build Success'"
        @click="downloadApk"
      >
        <feather-icon
          icon="DownloadIcon"
          class="mr-50"
        />
        Download Build
      </b-button>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: true,
    },
    siteId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    downloadUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      statuses: [{
        draft: 'Draft', 'Build Success': 'Build Success', 'Build Failed': 'Build Failed', 4: 'Resigned', Pending: 'Pending',
      },
      {
        draft: 'light-primary', 'Build Success': 'light-success', 'Build Failed': 'light-danger', 4: 'light-warning', Pending: 'light-info',
      }],
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
  },
  methods: {
    showModal() {
      this.$router.push({ name: 'build-app', params: { id: this.siteId } })
    },
    downloadApk() {
      window.location.href = this.downloadUrl
    },
  },
}
</script>
